import React from 'react';
import {useNavigate} from 'react-router-dom';


function Login() {
    let navigate = useNavigate();

    function handleSignUp(){
        navigate('/register');
    }


 return (
    <div className="login-page">
      <div className="login-form-container"> {/* Įtraukite šį div elementą */}
        <form className="login-form">
          <h2>Login</h2>
          <div className="input-group">
            <input type="text" id="username" name="username" placeholder="Type your username" required />
          </div>
          <div className="input-group">
            <input type="password" id="password" name="password" placeholder="Type your password" required />
          </div>
          <div className="forgot-password">
            <a href="#">Forgot password?</a>
          </div>
          <button type="submit" className="login-button">LOGIN</button>
          <div className="signup-text">
            Don't have an account? <button type="button" className="signup-button" onClick={handleSignUp}>Sign up!</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
