import React from 'react';


const About = () => {
  return (
    <div style={{ padding: '20px' }}>  // Papildomas stilius, jei reikia
      <h1>About Us</h1>
      <p>This is the about page content.</p>
    </div>
  );
};

export default About;
