import React, { useState } from 'react';
import axios from 'axios';

function RegistrationForm() {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    role: '',
    phone_number: '',
    branch: '',
    company: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('http://127.0.0.1:8000/api/register', formData)
      .then(response => {
        console.log('Registration successful', response.data);
      })
      .catch(error => {
        console.error('Error registering', error);
      });
  };

  return (
    <div className="registration-form-container">
      <form className="registration-form" method="POST" onSubmit={handleSubmit}>
        <h2>Registration</h2>
        
        <div className="form-row">
          <div className="input-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="Enter your name" required />
          </div>

          <div className="input-group">
            <label htmlFor="surname">Surname</label>
            <input type="text" id="surname" name="surname" value={formData.surname} onChange={handleChange} placeholder="Enter your surname" required />
          </div>
        </div>
        
        <div className="form-row">
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" required />
          </div>

          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} placeholder="Enter your password" required minLength="8" />
          </div>
        </div>
        
        <div className="form-row">
          <div className="input-group">
            <label htmlFor="phone_number">Phone Number</label>
            <input type="tel" id="phone_number" name="phone_number" value={formData.phone_number} onChange={handleChange} placeholder="Enter your phone number" required />
          </div>

          <div className="input-group">
            <label htmlFor="company">Company</label>
            <input type="text" id="company" name="company" value={formData.company} onChange={handleChange} placeholder="Enter your company name" required />
          </div>
        </div>

        <div className="form-row">
          <div className="input-group">
            <label htmlFor="branch">Branch</label>
            <input type="text" id="branch" name="branch" value={formData.branch} onChange={handleChange} placeholder="Enter your branch" required />
          </div>

          <div className="input-group">
            <label htmlFor="role">Role</label>
            <input type="number" id="role" name="role" value={formData.role} onChange={handleChange} placeholder="Enter your role ID" required />
          </div>
        </div>
        
        <button type="submit" className="register-button">Register</button>
      </form>
    </div>
  );
}

export default RegistrationForm;

