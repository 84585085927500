import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

// Function to get CSRF token and set it to axios headers
const setupCSRFToken = () => {
  axios.get('http://127.0.0.1:8000/csrf-token').then(response => {
    const csrfToken = response.data.csrfToken;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    console.log('CSRF token received:', csrfToken);
  }).catch(error => {
    console.error('Error getting CSRF token:', error);
  });
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Setup CSRF token after rendering the root
setupCSRFToken();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



