
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import backgroundImage from './background.jpg';
import Home from './Home';
import Services from './Services';
import About from './About';
import Contact from './Contact';
import Login from './Login';
import RegistrationForm from './RegistrationForm';

const App = () => {
  return (
    <Router>
      <div style={{ backgroundImage: `url(${backgroundImage})`, height: '100vh', backgroundSize: 'cover' }}>
        <header>
          <div className="logo">LOGO HERE</div>
          <nav>
            <ul>
              <li><Link to="/home" className="nav-item">HOME</Link></li>
              <li><Link to="/services" className="nav-item">SERVICES</Link></li>
              <li><Link to="/about" className="nav-item">ABOUT</Link></li>
              <li><Link to="/contact" className="nav-item">CONTACT</Link></li>
              <li><Link to="/login" className="nav-item">LOG IN</Link></li>
            </ul>
          </nav>
          <div className="menu">☰</div>
        </header>
        <Routes>
          <Route path="/" element={
            <div className="main-content">
              <h1 className="welcome-message">Welcome</h1>
              <div className="company-subtitle">To Our Website</div>
              <div className="text-container">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
              <button className="button">LEARN MORE</button>
            </div>
          } />
          <Route path="/home" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegistrationForm />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;





